<template>
  <vf-button class="w-full" :size="{ sm: 'sm', lg: 'md' }" @click="openNotifyMe">
    {{ $t.notifyMe }}
    <modal-notify-me size="xs" />
  </vf-button>
</template>

<script lang="ts" setup>
// NOTE The modal is inside the button so it can be the root component and get non-prop attributes assigned.
// The modal itself is rendered with a teleport so it will not affect the DOM.

import type { Product, ProductAttributesOptionsColor, ProductVariants } from '#root/api/clients/product/data-contracts'
import type { BaseNotification } from '#types/notification'
import type { AttributeSelection, SelectedVariant } from '#types/product'

const props = defineProps<{
  attributeSelection: AttributeSelection
  attributeSelectionLabels: ProductVariants[number]['attributes']
  isComingSoon?: boolean
  product: Product
  productColor: ProductAttributesOptionsColor | null
  selectedVariant: SelectedVariant | null
}>()

const emit = defineEmits<{
  notify: [notification: BaseNotification]
  noVariantSelected: []
}>()

const ModalNotifyMe = createModal('notify-me')

const { $gtm } = useNuxtApp()

const setNotification = (notification: BaseNotification) => {
  emit('notify', notification)
}

const openNotifyMe = () => {
  if (!props.selectedVariant) {
    emit('noVariantSelected')
    scrollToElement('sizePickers')
    return
  }

  const size = props.attributeSelectionLabels.size || props.attributeSelection.size
  const length = props.attributeSelection.length
  const width = props.attributeSelection.width

  const gtmEventLabel = `${props.product.id} - ${props.productColor?.label}: ${[size, length, width].filter(Boolean).join('/')}`

  ModalNotifyMe.open({
    productId: props.selectedVariant.id,
    selectedAttributes: props.attributeSelectionLabels,
    isComingSoon: props.isComingSoon,
    gtmEventLabel,
    setNotification,
    productName: props.product.name
  })

  $gtm.push('pdpPage.onNotifyMeOpen', props.product.id)
}
</script>
