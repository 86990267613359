<template>
  <article
    class="text-sm space-y-2"
    :data-test-id="`customer-detailed-reviews-${review.reviewId}`"
  >
    <!-- Review Headline -->
    <div
      class="f-col gap-2 md:flex-row"
      :data-test-id="`customer-reviews-section-${review.reviewId}`"
    >
      <div class="flex gap-1">
        <vf-rating
          :value="review.metrics.rating"
          :data-test-id="`customer-rating-${review.reviewId}`"
        />
        <span>({{ review.metrics.rating }})</span>
      </div>
      <p
        class="title-5 overflow-wrap-anywhere"
        :data-test-id="`review-headline-${review.reviewId}`"
      >
        {{ isTranslated ? translatedReview.translated_headline : review.details.headline }}
      </p>
    </div>
    <div
      class="flex flex-col-reverse gap-2 lg:flex-row lg:gap-16 lg:between"
      :data-test-id="`customer-review-details-${review.reviewId}`"
    >
      <!-- Review Body -->
      <div class="lg:w-2/3 lg:shrink-0 space-y-2">
        <p
          class="overflow-wrap-anywhere lg:mb-4 "
          :data-test-id="`customer-review-comment-${review.reviewId}`"
        >
          <template v-if="isTranslated">
            {{ translatedReview.translated_comments }}
          </template>
          <base-interpolator
            v-else
            :content="review.details.comments.replace(new RegExp(searchTerm, 'gi'), '{bold|$&}')"
          >
            <template #bold="{ args: [message] }">
              <b>{{ message }}</b>
            </template>
          </base-interpolator>
        </p>
        <div v-if="$feature.pdpReviewTranslate && isReviewTranslatable">
          <base-button v-if="!isTranslated" class="underlined" @click="handleTranslate(locale)">
            {{ $t.reviewTranslate }}
          </base-button>
          <base-button v-else class="underlined" @click="isTranslated = false">
            {{ $t.reviewTranslateBack }}
          </base-button>
        </div>
        <div v-if="review.details.merchantResponse" class="bg-grey-90 p-4 ">
          <p class="mb-2">
            {{ $t.reviewResponseFromBrand }}
          </p>
          <p>{{ review.details.merchantResponse }}</p>
        </div>
        <base-collapse v-if="review?.details?.properties?.length" disable-visibility-toggle>
          <template #toggle="{ expanded }">
            <div class="flex items-center">
              <span >
                {{ expanded ? $t.lessDetails : $t.moreDetails }}
              </span>
              <vf-icon name="chevron" size="md" :dir="expanded ? 'up' : 'down'" />
            </div>
          </template>
          <div class="grid cols-2 mt-2 gap-8 lg:cols-3">
            <div v-for="{ key, label, value } in review.details.properties" :key="key">
              <p class="bg-grey-80 px-2 py-1 ">
                {{ label }}
              </p>
              <ul class="px-2 py-1 space-y-2">
                <li v-for="entry in value" :key="entry">
                  {{ entry }}
                </li>
              </ul>
            </div>
          </div>
        </base-collapse>
        <div v-if="review.media?.length" class="flex gap-2 wrap">
          <base-button
            v-for="({ id, type, caption, uri }, i) in review.media.slice(0, 3)"
            :key="id"
            :aria-label="$t.reviewUgcViewInPopup"
            @click="ModalReviewUgc.keep().open({
              productId,
              reviewUgcMedia,
              activeIndex: i,
              isCarousel: false,
            })"
          >
            <base-picture
              v-if="type === 'image'"
              :alt="caption"
              :src="uri"
              height="98"
              width="98"
              :props-img="{ class: 'aspect-square w-a', style: 'height: 6.125rem' }"
            />
            <base-video v-else-if="type === 'video'" :src="uri" style="height: 6.125rem" />
          </base-button>
        </div>
        <p
          v-if="review.details.bottomLine"
          class="f-col gap-2 lg:flex-row lg:gap-4"
          :data-test-id="`customer-review-bottom-line-recommendation-${review.reviewId}`"
        >
          <span class="fw-bold">
            {{ $t.reviewBottomLine }}
          </span>
          <span v-if="review.details.bottomLine === 'Yes'" >
            {{ $t.reviewBottomLinePositive }}
          </span>
          <span v-if="review.details.bottomLine === 'No'" >
            {{ $t.reviewBottomLineNegative }}
          </span>
        </p>
        <div class="f-col gap-2" :data-test-id="`customer-review-helpful-section-${review.reviewId}`">
          <p >
            {{ $t.reviewVoteCTA }}
          </p>
          <div class="flex items-center">
            <base-button
              class="flex items-center gap-1"
              :aria-label="replaceAll($pluralize($t.reviewVoteHelpful, helpfulVotes), { count: helpfulVotes })"
              @click="handleVote({ voteType: 'helpful' })"
            >
              <vf-icon name="thumbs-up" size="md" />
              {{ Math.min(helpfulVotes, 99) }}
            </base-button>
            <base-button
              class="ml-2 flex items-center gap-1"
              :aria-label="replaceAll($pluralize($t.reviewVoteNotHelpful, notHelpfulVotes), { count: notHelpfulVotes })"
              @click="handleVote({ voteType: 'unhelpful' })"
            >
              <vf-icon name="thumbs-up" size="md" dir="left" />
              {{ Math.min(notHelpfulVotes, 99) }}
            </base-button>
            <vf-link
              v-if="!flagged"
              class="ml-4 text-xs"
              @click="ModalReportIssue.keep().open({ reviewId: review.reviewId })"
            >
              {{ $t.reviewFlagCTA }}
            </vf-link>
            <p v-else class="ml-4 text-xs nowrap">
              {{ $t.youFlaggedReview }}
            </p>
          </div>
        </div>
      </div>
      <!-- Review Info -->
      <div
        class="text-xs lg:w-40 "
        :data-test-id="`reviewer-details-${review.reviewId}`"
      >
        <p :data-test-id="`reviewer-submitted-time-${review.reviewId}`">
          <span >{{ $t.reviewSubmittedOn }}&nbsp;</span>
          <time>{{ useTimeSince(review.details.createdDate) }}</time>
        </p>
        <p
          class="line-clamp-1 overflow-wrap-anywhere lg:line-clamp-2"
          :data-test-id="`reviewer-name-${review.reviewId}`"
        >
          <span >{{ $t.reviewSubmittedBy }}&nbsp;</span>
          <span>{{ review.details.nickname }}</span>
        </p>
        <p
          class="line-clamp-1 overflow-wrap-anywhere lg:line-clamp-2"
          :data-test-id="`reviewer-location-${review.reviewId}`"
        >
          <span >{{ $t.reviewSubmittedFrom }}&nbsp;</span>
          <span>{{ review.details.location }}</span>
        </p>
        <p
          v-if="review.badges.isVerifiedBuyer"
          class="mt-2 flex items-center gap-1"
          :data-test-id="`reviewer-type-${review.reviewId}`"
        >
          <vf-icon name="success"  />
          <span>{{ $t.reviewVerifiedBuyer }}</span>
        </p>
        <p
          v-if="$t.reviewDisclosure?.[`${review.details.disclosureCode}`]"
          class="mt-2 overflow-wrap-anywhere"
          :data-test-id="`reviewer-disclosure-${review.reviewId}`"
        >
          <span>{{ $t.reviewDisclosure?.[`${review.details.disclosureCode}`] }}</span>
        </p>
      </div>
    </div>
    <modal-review-ugc class-content="md:w-152 lg:w-164" size="md" />
    <modal-report-issue v-slot="attrs">
      <dialog-report-issue v-bind="attrs" @flagged="flagged = true" />
    </modal-report-issue>
  </article>
</template>

<script lang="ts" setup>
import type { ProductReviewsReview, ProductReviewsVoteRequest } from '#root/api/clients/product/data-contracts'

const { productId, review, searchTerm = '' } = defineProps<{
  /**
   * Product ID
   */
  productId: string
  /**
   * Product review data
   */
  review: ProductReviewsReview
  /**
   * Search Term
   */
  searchTerm?: string
}>()

const ModalReviewUgc = createModal('review-ugc')
const ModalReportIssue = createModal('report-issue')

const { pdpReviewTranslate } = useFeatureFlags()

const hasVoted = ref(false)
const helpfulVotes = ref(review?.metrics?.helpfulVotes)
const notHelpfulVotes = ref(review?.metrics?.notHelpfulVotes)
const flagged = ref(false)
const isTranslated = ref(false)
const translatedReview = ref()
const [locale] = useLocale().split('-')

const isReviewTranslatable = computed(() => {
  const [reviewLocale] = review.details.locale.split('_')
  return reviewLocale !== locale
})

function handleVote({ voteType }: ProductReviewsVoteRequest) {
  if (!hasVoted.value) {
    useApi().products.reviewVote(productId, review.reviewId, { voteType })
    voteType === 'helpful' ? helpfulVotes.value++ : notHelpfulVotes.value++
    hasVoted.value = true
  }
}

function handleTranslate(lang) {
  if (!isTranslated.value && pdpReviewTranslate) {
    useApi().products.$reviewTranslate(review.reviewId, { target_language: lang })
      .then((res) => {
        if (res.results) {
          translatedReview.value = res?.results[0].translation_document
          isTranslated.value = true
        }
      })
  }
}

const reviewUgcMedia: ProductReviewsReview[] = review.media?.map((item) => ({
  ...review,
  media: [item]
})) || []
</script>
