<template>
  <ul :class="variant === 'modal' ? 'space-y-6' : 'space-y-4'" class="dark:c-white">
    <li v-for="({ label, text, thumbnail, url }, i) in data" :key="i">
      <component
        :is="url ? BaseLink : 'div'"
        :to="url"
        class="group flex items-start"
        :class="variant === 'modal' ? 'gap-6' : 'gap-4'"
      >
        <base-picture
          v-if="showImages && thumbnail"
          :src="thumbnail"
          :alt="label"
          :width="variant === 'modal' ? 60 : 52"
          :height="variant === 'modal' ? 60 : 52"
          class="aspect-square w-full shrink-0"
          :class="{ invisible: !thumbnail }"
          :style="`max-width: ${variant === 'modal' ? 3.75 : 3.25}rem`"
          fit="contain"
        />
        <div class="grow space-y-1">
          <p
            v-if="label"
            class="!title-5 fw-bold"
            :class="{ 'group-hover:underlined': url }"
          >
            {{ label }}
          </p>
          <p
            v-if="text"
            class="subtitle-4 "
            :class="{ 'group-hover:underlined': url && !label }"
          >
            {{ text }}
          </p>
        </div>
      </component>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { BaseLink } from '#components'
import type { ProductDetailContent } from '#types/product'

const props = defineProps<{
  data: ProductDetailContent[]
  variant?: 'modal'
}>()

const showImages = props.data?.some(({ thumbnail }) => !!thumbnail)
</script>
