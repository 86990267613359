<template>
  <ul class="flex gap-x-2 gap-y-4 wrap dark:c-white">
    <li v-for="{ label, thumbnail } of data" :key="label" class="w-22">
      <base-button
        class="w-full hover:underlined"
        @click="ModalAccordion.open({ data, title })"
      >
        <base-picture
          v-if="thumbnail"
          :src="thumbnail"
          :alt="label"
          width="64"
          height="64"
          class="mb-4 mt-3 aspect-square w-16"
          :class="{ invisible: !thumbnail }"
          fit="contain"
        />
        <p v-if="label" class="line-clamp-2 break-words">
          {{ label }}
        </p>
      </base-button>
    </li>
    <modal-accordion size="sm" />
  </ul>
</template>

<script setup lang="ts">
import type { ProductDetailContent } from '#types/product'

defineProps<{
  data: ProductDetailContent[]
  title: string
}>()

const ModalAccordion = createModal('accordion-modal')
</script>
