<template>
  <section class="dark:c-white">
    <h2 v-if="showHeader" class="mb-2 text-sm fw-bold" data-test-id="size-fit-model-header">
      {{ $t.sizeAndFit }}
    </h2>
    <div v-if="product.modelMeasurements?.length" class="mb-4" data-test-id="size-fit-model">
      <p
        v-for="(modelMeasurement, i) in product.modelMeasurements"
        :key="i"
        class="text-xs "
        data-test-id="size-fit-model-content"
      >
        {{ modelMeasurement }}
      </p>
    </div>
    <div
      v-if="reviewsData?.paging.totalResults && fitRating?.display_values"
      class="mb-4 b b-grey-50 p-4 space-y-2 b-transparent space-y-0 !p-0"
    >
      <p class="text-xs ">
        {{ $t.fitBasedOn }}
        <base-link to="#reviews" class="underlined">
          {{ replaceAll(
            $pluralize($t.numberOfReviews, reviewsData?.rollup?.reviewCount!),
            { reviewsCount: reviewsData?.rollup?.reviewCount })
          }}
        </base-link>
      </p>
      <vf-meter
        :min-label="fitRating.display_values[0]"
        :max-label="fitRating.display_values[fitRating.display_values.length - 1]"
        :max-value="fitRating.display_values.length"
        :value="getFitRatingValue(fitRating)"
      />
    </div>
    <div class="flex text-sm underlined between">
      <div v-if="$feature.showFitFinder" id="fit-finder-container" />
      <base-button v-else-if="product.fitSize" class="lh-3">
        {{ $t.findYourSize }}
      </base-button>
      <span v-if="product.sizeChart" class="flex gap-1">
        <vf-icon v-if="showIcon" name="size" size="md" />
        <base-button id="pdp-size-chart" class="lh-3 " @click="$emit('openSizeGuide')">
          {{ $t.sizeChart }}
        </base-button>
      </span>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { Product, ProductReviewsRollupProperty } from '#root/api/clients/product/data-contracts'
import type { ProductReviewsData } from '#types/components/product/reviews'

defineProps<{
  product: Product
  reviewsData?: ProductReviewsData
  fitRating?: ProductReviewsRollupProperty
}>()

defineEmits<{
  openSizeGuide: []
}>()

const { showHeader, showIcon } = useAppConfig().components.product.details.sizeAndFit
</script>
